import request from '@/utils/request'
// 列表
export function getTableList(data) {
  return request('post', '/apm/message/notice/config/list', data)
}

// 获取用户列表
export function getUserList(data) {
  return request('post', '/apm/message/notice/config/getUserList', data)
}

// 获取通知列表
export function getNoticeList(data) {
  return request('post', '/apm/message/notice/config/getTemplateList', data)
}


// 详情
export function getTableInfo(data) {
  return request('post', '/apm/message/notice/config/info', data)
}

// 新增
export function getTableAdd(data) {
  return request('post', '/apm/message/notice/config/create', data)
}

// 编辑
export function getTableEdit(data) {
  return request('post', '/apm/message/notice/config/update', data)
}

// 获取二维码信息
export function getCode(data) {
  return request('post', '/apm/message/notice/config/get/qrCode', data)
}

// 删除用户
export function getUserDel(data) {
  return request('post', '/apm/message/notice/config/del/user', data)
}

// 停用或启用
export function getStopStart(data) {
  return request('post', '/apm/message/notice/config/operate', data)
}