<template>
  <div class="weixin">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增通知</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="通知名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.noticeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.nickname || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" align="center" min-width="280">
        <template slot-scope="scope">
          <span>{{ scope.row.templateName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span :style="{ 'color': noticeStatusColor[scope.row.noticeStatus] }">{{ noticeStatusObj[scope.row.noticeStatus]
            || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onStart(scope.row)"
            v-if="scope.row.noticeStatus == '2'">启用</el-button>
          <el-button type="text" size="medium" @click.stop="onStop(scope.row)"
            v-if="scope.row.noticeStatus == '1'">停用</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="通知名称" prop="noticeName">
            <el-input v-model.trim="params.noticeName" autocomplete="off" maxlength="16" placeholder="请填写通知名称"></el-input>
          </el-form-item>
          <el-form-item label="已选门店">
            {{ tableParams.shopName }}
          </el-form-item>
          <el-form-item label="选择用户" prop="wechatUserIdSet" class="long-content">
            <el-checkbox :indeterminate="userCheckItem" v-model="userCheckAll" @change="onUserAll">全选</el-checkbox>
            <el-checkbox-group v-model="params.wechatUserIdSet" @change="onUserItem">
              <span class="user-item" v-for="item in userList" :key="item.id">
                <el-checkbox :label="item.id">
                  <img class="user-img" :src="item.headImgUrl || userAvatar" />
                  {{ item.nickname || "未知" }}
                </el-checkbox>
                <i class="el-icon-close del-icon" @click.stop="onDelUser(item)"></i>
              </span>
            </el-checkbox-group>
            <el-button @click.stop="onAddUser"><i class="el-icon-plus"></i>新增用户</el-button>
          </el-form-item>
          <el-form-item label="选择通知" prop="templateIdSet" class="long-content">
            <el-checkbox :indeterminate="noticeCheckItem" v-model="noticeCheckAll" @change="onNoticeAll">全选</el-checkbox>
            <el-checkbox-group v-model="params.templateIdSet" @change="onNoticeItem">
              <el-checkbox v-for="item in noticeList" :key="item.templateId" :label="item.templateId">
                {{ item.templateName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
    <!-- 二维码弹框 -->
    <el-dialog title="扫码关注" :visible.sync="codeShow" :close-on-click-modal="false" append-to-body width="15%" top="25vh">
      <div class="code">
        <p>1.扫码关注公众号</p>
        <vue-qr :logoSrc="codeImageUrl" :text="codeText" :size="200"></vue-qr>
        <p class="mb15">2.关注完成，点击【我已关注】</p>
        <el-button type="primary" @click="onPay">我已关注</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTableList,
  getUserList,
  getNoticeList,
  getTableInfo,
  getTableAdd,
  getTableEdit,
  getCode,
  getUserDel,
  getStopStart
} from "@/api/notice/weixin";
import { noticeStatusObj, noticeStatusColor } from "@/db/objs";
import { rules } from "@/db/rules";
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      noticeStatusObj,
      noticeStatusColor,
      userList: [],
      noticeList: [],
      userAvatar:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        keyWord: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
      // 弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // from表单
      rules,
      shopIdArr: [],
      params: {
        noticeName: "",
        merchantId: "",
        shopId: "",
        wechatUserIdSet: [],
        templateIdSet: []
      },
      // 全选
      userCheckAll: false,
      userCheckItem: false,
      noticeCheckAll: false,
      noticeCheckItem: false,
      // 二维码
      codeShow: false,
      codeImageUrl: require("../../assets/images/mine/logo.png"),
      codeText: "",
    }
  },
  created() {
    let merchantShopInfo = JSON.parse(window.localStorage.getItem('playOne-store-merchantShopInfo'));
    if (merchantShopInfo) {
      Object.assign(this.tableParams, merchantShopInfo);
      Object.assign(this.params, merchantShopInfo);
    }
  },
  mounted() {
    this.getTableList();// 【请求】表格数据
    this.getUserList();// 【请求】表格数据
    this.getNoticeList();// 【请求】表格数据
  },
  methods: {
    // 【请求】表格数据
    getTableList() {
      let data = this.tableParams;
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】用户列表
    getUserList() {
      let data = {
        shopId: this.tableParams.shopId
      };
      getUserList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.userList = res.data;
        }
      });
    },

    // 【请求】通知列表
    getNoticeList() {
      let data = {};
      getNoticeList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.noticeList = res.data;
        }
      });
    },

    // 【请求】列表详情
    getTableInfo(row) {
      getTableInfo(row).then((res) => {
        if (res.isSuccess == "yes") {
          this.params = res.data;
          this.onUserItem(res.data.wechatUserIdSet);
          this.onNoticeItem(res.data.templateIdSet);
        }
      });
    },

    // 【请求】添加
    getTableAdd() {
      let data = this.params;
      getTableAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.reload();// 【请求】表格数据
        }
      });
    },

    // 【请求】编辑
    getTableEdit() {
      let data = this.params;
      getTableEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getTableList();// 【请求】表格数据
        }
      });
    },

    // 【请求】获取二维码信息
    getCode() {
      let data = {
        shopId: this.tableParams.shopId
      };
      getCode(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.codeText = res.data;
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】启用
    onStart(row) {
      let data = {
        shopId: this.tableParams.shopId,
        id: row.id,
        noticeStatus: 1
      };
      this.$confirm('你确定要启用吗?', '启用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getStopStart(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getTableList();
          }
        })
      });
    },

    // 【监听】停用
    onStop(row) {
      let data = {
        shopId: this.tableParams.shopId,
        id: row.id,
        noticeStatus: 2
      };
      this.$confirm('你确定要停用吗?', '停用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getStopStart(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getTableList();
          }
        })
      });
    },


    // 【监听】编辑
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增通知";
      this.params = {
        noticeName: "",
        merchantId: this.params.merchantId,
        shopId: this.params.shopId,
        wechatUserIdSet: [],
        templateIdSet: []
      };
      this.userCheckAll = false;
      this.userCheckItem = false;
      this.noticeCheckAll = false;
      this.noticeCheckItem = false;
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】详情
    onInfo(row) {
      console.log('row: ', row);
    },

    // 【监听】编辑
    onEdit(row) {
      console.log('row: ', row);
      this.editShow = true;
      this.editTitle = "编辑";
      this.userCheckAll = false;
      this.userCheckItem = false;
      this.noticeCheckAll = false;
      this.noticeCheckItem = false;
      this.getTableInfo(row);
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.id) {
            this.getTableEdit();
          } else {
            this.getTableAdd();
          }
        } else {
          return false;
        }
      });
    },

    // 【监听】删除用户
    onDelUser(item) {
      item.shopId = this.params.shopId;
      item.wechatUserId = item.id;
      this.$confirm('你确定要删除该用户吗?', '删除用户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getUserDel(item).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getUserList();
          }
        })
      });
    },

    // 【转换】全部用户列表id集合
    changeUserId(list) {
      let arr = [];
      if (list.length) {
        for (let i of list) {
          arr.push(i.id)
        }
      }
      return arr
    },

    // 【转换】全部通知列表id集合
    changeNoticeId(list) {
      let arr = [];
      if (list.length) {
        for (let i of list) {
          arr.push(i.templateId)
        }
      }
      return arr
    },

    // 【监听】用户全选
    onUserAll(flag) {
      this.params.wechatUserIdSet = flag ? this.changeUserId(this.userList) : [];
      this.userCheckItem = false;
    },

    // 【监听】用户单选
    onUserItem(item) {
      this.userCheckAll = item.length === this.changeUserId(this.userList).length;
      this.userCheckItem = item.length > 0 && item.length < this.changeUserId(this.userList).length;
    },

    // 【监听】用户全选
    onNoticeAll(flag) {
      this.params.templateIdSet = flag ? this.changeNoticeId(this.noticeList) : [];
      this.userCheckItem = false;
    },

    // 【监听】用户单选
    onNoticeItem(item) {
      this.noticeCheckAll = item.length === this.changeNoticeId(this.noticeList).length;
      this.noticeCheckItem = item.length > 0 && item.length < this.changeNoticeId(this.noticeList).length;
    },

    // 【监听】新增用户
    onAddUser() {
      this.codeShow = true;
      this.getCode();
    },

    // 【监听】我已关注
    onPay() {
      this.codeShow = false;
      this.getUserList();
    }
  }
}
</script>

<style lang="scss" scoped>
.weixin {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.code {
  display: flex;
  flex-direction: column;
  align-items: center;

  >p {
    width: 100%;
    display: flex;
  }
}

.user-item {
  margin-right: .15rem;
  margin-bottom: .15rem;
  display: inline-flex;
  align-items: center;
}

.user-img {
  display: inline-flex;
  width: 14px;
  border-radius: 50%;
}


.del-icon {
  font-size: .16rem;
  cursor: pointer;
  margin-left: .05rem;
}
</style>
